import React, { useState,useEffect } from "react";
import "./Gallery.css";
import GalleryTabs from "./GalleryTabs";
import ImageGallery from "./ImageGallery";
import VideoGallery from "./VideoGallery";
import AOS from 'aos';
import 'aos/dist/aos.css'



export default function Gallery() {
  const [selected_tab, setSelected] = useState(1);


  useEffect(() => {
    AOS.init({disable: 'mobile',mirror:true});
    AOS.refresh();
  }, []);

  return (<>
     <section className="bg-about">
      <img
            style={{
              width: "400px",
              position: "absolute",
              left: 0,
              bottom: 0,
              zIndex: 1,
              opacity: 0.8,
            }}
            src="images/bannerLeft.png"
            alt=""
            className="img-fluid"
          />

          <img
            style={{
              width: "400px",
              position: "absolute",
              right: 0,
              top: 0,
              zIndex: 1,
              opacity: 0.8,
            }}
            src="images/bannerRight22.png"
            alt=""
            className="img-fluid"
          />
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-center">
            <div style={{zIndex: 2}} data-aos="fade-up"
                data-aos-delay="0" data-aos-easing="ease-in-sine">
              <h1 className="bannerHeading2">Gallery</h1>
            </div>
            </div>
          </div>
        </div>
      </section>
      
      <section class="p-55-0">
      <div class="container">
        <div class="row">
        <div style={{zIndex: 2}} data-aos="fade-up"
                data-aos-delay="200" data-aos-easing="ease-in-sine">
          <GalleryTabs setSelected={setSelected} selected_tab={selected_tab} />
          {selected_tab === 1 && <ImageGallery />}
          {selected_tab === 2 && <VideoGallery />}
          </div>
        </div>
      </div>
    </section>
    
    </>
   
  );
}
