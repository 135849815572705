import React from "react";
import SimpleReactLightbox, { SRLWrapper } from "simple-react-lightbox";
import image1 from "../../assets/img1.png"
import image2 from "../../assets/img2.png"
import image3 from "../../assets/img3.png"
import image4 from "../../assets/img4.png"
import image5 from "../../assets/img5.png"
import image6 from "../../assets/img6.png"
import image7 from "../../assets/img7.png"
import image8 from "../../assets/img8.png"
import image9 from "../../assets/img9.png"

const options = {
  buttons: {
    backgroundColor: "rgba(140, 94, 88, 0.8)",
    iconColor: "rgba(241, 191, 152, 0.7)",
  },
  settings: {
    overlayColor: "rgba(255, 237, 225, 1)",
    transitionSpeed: 1000,
    transitionTimingFunction: "linear",
  },
  thumbnails: {
    thumbnailsSize: ["120px", "100px"],
    thumbnailsOpacity: 0.4,
  },
  caption: {
    captionColor: "rgba(241, 191, 152, 1)",
  },
  progressBar: {
    size: "4px",
    backgroundColor: "rgba(255, 237, 225, 1)",
    fillColor: "#AF9AB2",
  },
};

const images = [
  image1,image2,image3,image4,image5,image6,image7,image8,image9
];

export default function ImageGallery() {
  return (
    <SimpleReactLightbox>
      <SRLWrapper options={options}>
        <div id="gallery-with-links" className="content-image">
          <div className="row">
            {images.map((img, index) => (
              <div className="col-md-4 col-12 col-image-with-link">
                <a href={img}>
                  <img
                    src={img}
                    alt={index}
                    style={{ width: 300, borderRadius: 15 }}
                  />
                </a>
              </div>
            ))}
          </div>
        </div>
      </SRLWrapper>
    </SimpleReactLightbox>
  );
}
