import React from "react";
import { BsMap } from "react-icons/bs";
function Location() {
  return (
    <>
      <section className="bg-about">
        <img
          style={{
            width: "400px",
            position: "absolute",
            left: 0,
            bottom: 0,
            zIndex: 1,
            opacity: 0.8,
          }}
          src="images/bannerLeft.png"
          alt=""
          className="img-fluid"
        />

        <img
          style={{
            width: "400px",
            position: "absolute",
            right: 0,
            top: 0,
            zIndex: 1,
            opacity: 0.8,
          }}
          src="images/bannerRight22.png"
          alt=""
          className="img-fluid"
        />
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-center">
              <h1 className="bannerHeading2">Locations </h1>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container">
          <div
            className="row text-center"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: "50px 20px",
            }}
          >
            <div className="col-xs-12">
              <BsMap style={{ fontSize: "20px" }} />
              <p
                className="ff-thunder fc-orange"
                daata-aoos="fade-up"
                style={{ fontSize: "30px" }}
                daata-aoos-duration="1000"
              >
                Find Us at these Locations
              </p>
              {/* <p
                className="cardText"
                daata-aoos="fade-up"
                style={{ fontSize: "16px", maxWidth: "600px" }}
                daata-aoos-duration="1000"
              >
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s.
              </p> */}
              <hr />
            </div>
          </div>
          <div
            className="row text-center"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: "50px 20px",
            }}
          >
             <div className="col-xs-12 col-md-6">
             <h1 class="cardTitle2">Newyork City</h1>
             <p class="cardText" style={{textAlign:'left',fontSize:"20px",padding:5,margin:0}}>1439 Third Avenue</p>
             <p class="cardText" style={{textAlign:'left',fontSize:"20px",padding:5,margin:0}}>New York , NY 10057</p>
             
             </div>
             <div className="col-xs-12 col-md-6">
             <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d193596.00927373368!2d-74.14448639543548!3d40.697370922499324!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c24fa5d33f083b%3A0xc80b8f06e177fe62!2sNew%20York%2C%20NY%2C%20USA!5e0!3m2!1sen!2s!4v1692018445398!5m2!1sen!2s" width="100%" height="400" style={{border:0}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
             </div>
          </div>
          <br/><br/>
          
        </div>
      </section>
    </>
  );
}

export default Location;
