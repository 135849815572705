export let connection_string;
export let image_url;
export let vendor_register;
export let vendor_login;

// if (window.location.hostname == "localhost") {
//   connection_string = "http://localhost:5030/api/v1";
//   image_url = "http://localhost:5030/";
//   vendor_register = "https://surihatev.com/surihatev/surihatevendor/register";
//   vendor_login = "https://surihatev.com/surihatev/surihatevendor";
// }else {
  connection_string = "https://surihatev.com:5030/api/v1";
  image_url = "https://surihatev.com:5030/";
  vendor_register = "https://surihatev.com/surihatev/surihatevendor/register";
  vendor_login = "https://surihatev.com/surihatev/surihatevendor";
// }
