import React from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { image_url } from "../Util/connection_strings";
import StarRatings from "react-star-ratings";
import AOS from 'aos';
import 'aos/dist/aos.css'



export default function ProductCard2({ product, lg }) {



  useEffect(() => {
    AOS.init({disable: 'mobile',mirror:true});
    AOS.refresh();
  }, []);



  return (
    <div className="col-lg-3 my-2">
      <div style={{zIndex: 2}} data-aos="zoom-in-up"
                      data-aos-delay="200" data-aos-easing="ease-in-sine"> 
       <div
                            style={{
                              width: "100%",
                              minHeight: "250px",
                              backgroundColor: "#F5F5F5",
                              borderRadius: "30px",
                              padding:"30px",
                              display:'flex',
                              justifyContent:"center",
                              alignItems:'center'
                            }}
                          >
      <Link to={`/product/${product?._id}`}>
        <div className="productCardU">
          <div className="productImgU position-relative">
            <img
              src={`${image_url}${product?.images[0]}`}
              alt=""
              className="img-fluid"
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = "img/testimonial.jpg";
              }}
              style={{width:"100%"}}
            />
            <div className="overlayHover">
              <button type="button" className="btn btn-bag">
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="25" viewBox="0 0 23 27" fill="none">
<path d="M16.8126 6.67756H13.2549V4.97662C13.2549 3.83623 12.8019 2.74254 11.9955 1.93616C11.1892 1.12978 10.0955 0.676758 8.95508 0.676758C7.81468 0.676758 6.721 1.12978 5.91462 1.93616C5.10823 2.74254 4.65521 3.83623 4.65521 4.97662V6.67756H1.09753H0.847534V6.92756V24.0581V24.3081H1.09753H11.627C12.1534 25.0602 12.8539 25.675 13.6695 26.0993C14.525 26.5445 15.4794 26.7657 16.4436 26.7423C17.4077 26.7189 18.3503 26.4516 19.1832 25.9655C20.0162 25.4793 20.7124 24.7901 21.207 23.9621C21.7016 23.1341 21.9784 22.1944 22.0115 21.2305C22.0447 20.2666 21.8332 19.3101 21.3968 18.45C20.9603 17.59 20.3131 16.8545 19.5156 16.3122C18.781 15.8128 17.941 15.4922 17.0626 15.3746V6.92756V6.67756H16.8126ZM10.6137 20.5015C10.5454 21.2547 10.6278 22.0128 10.8548 22.7317H2.42391V8.25393H4.65521V11.3676V11.6176H4.90521H5.98159H6.23159V11.3676V8.25393H11.6786V11.3676V11.6176H11.9286H13.0049H13.2549V11.3676V8.25393H15.4862V15.3764C14.6947 15.4888 13.9348 15.7666 13.2564 16.1928C12.5141 16.6592 11.8894 17.2907 11.431 18.038C10.9726 18.7854 10.693 19.6284 10.6137 20.5015ZM6.23159 4.97662C6.23159 4.25431 6.51853 3.56158 7.02928 3.05083C7.54004 2.54007 8.23277 2.25313 8.95508 2.25313C9.67739 2.25313 10.3701 2.54007 10.8809 3.05083C11.3916 3.56158 11.6786 4.25431 11.6786 4.97662V6.67756H6.23159V4.97662ZM16.3037 25.1724C15.4863 25.1723 14.6872 24.9299 14.0076 24.4758C13.3278 24.0216 12.798 23.376 12.4851 22.6207C12.1723 21.8654 12.0904 21.0343 12.2499 20.2324C12.4094 19.4306 12.8031 18.6941 13.3812 18.116C13.9593 17.5379 14.6958 17.1442 15.4976 16.9847C16.2995 16.8252 17.1306 16.9071 17.8859 17.22C18.6412 17.5328 19.2868 18.0626 19.741 18.7424C20.1951 19.422 20.4375 20.2211 20.4376 21.0385C20.4362 22.1345 20.0003 23.1852 19.2253 23.9601C18.4504 24.7351 17.3997 25.171 16.3037 25.1724Z" fill="#145B3D" stroke="#145B3D" stroke-width="0.5"/>
<path d="M16.9845 19.0852V18.8352H16.7345H15.6581H15.4081V19.0852V20.918H13.578H13.328V21.168V22.2444V22.4944H13.578H15.4081V24.3245V24.5745H15.6581H16.7345H16.9845V24.3245V22.4944H18.8146H19.0646V22.2444V21.168V20.918H18.8146H16.9845V19.0852Z" fill="#145B3D" stroke="#145B3D" stroke-width="0.5"/>
</svg>
              </button>
            </div>
          </div>
          
        </div>
        {/* productCardU */}
      </Link>
      </div>
      <div style={{ padding: "10px 20px" }}>
                            <h3 className="productTitle">{product?.name}</h3>

                            <span className="productPrice">
                            ₵{product?.price}{" "}
                            </span>
                          </div>
                          </div>
    </div>
  );
}
