import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useQuery } from "react-query";
import { Image } from "antd";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import { query_stale_time } from "../../Helpers/Helpers";
import { getProductsByCategory, getTrendingProducts } from "../../Apis/Product";
import { queryClient } from "../..";
import { image_url } from "../../Util/connection_strings";
import { getUserCategories } from "../../Apis/Category";
import ProductCardSkeleton from "../../Components/SkeletonCards/ProductCard";
import ProductCard from "../../Components/ProductCard2";
import Section1 from "./Sections/SectionOne";
import Section2 from "./Sections/SectionTwo";
import Section3 from "./Sections/SectionThree";
import Section4 from "./Sections/SectionFour";
import Section5 from "./Sections/SectionFive";
import Section6 from "./Sections/SectionSix";
import Section7 from "./Sections/SectionSeven";
import Section8 from "./Sections/SectionEight";
import Section9 from "./Sections/SectionNine";
import Section10 from "./Sections/SectionTen";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";

export default function Home() {
  const settings = {
    // dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 3,
    className: "product-sliderU position-relative",
  };
  const setting2 = {
    // dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const [search_string, setSearchString] = useState("");
  const [page, setPage] = useState(1);
  const [perPage] = useState(10);
  const [sort, setSort] = useState("");
  const [min, setMin] = useState();
  const [max, setMax] = useState();
  const [rating, setRating] = useState();

  const responsive = {
    0: { items: 1 },

    568: { items: 2 },
    992: { items: 3 },

    1024: { items: 4 },

    1300: { items: 5 },

    1550: { items: 6 },
  };

  const { isLoadingTrending, data: trending } = useQuery(
    ["trending_products"],
    () => getTrendingProducts(),
    {
      staleTime: query_stale_time,
    }
  );

  const { isLoadingCategory, data: categories } = useQuery(
    ["categories"],
    () => getUserCategories(),
    {
      staleTime: query_stale_time,
    }
  );

  useEffect(() => {
    AOS.init({ disable: "mobile", mirror: true });
    AOS.refresh();
  }, []);

  const { isLoading, data } = useQuery(
    ["products", "", "", page, perPage, search_string, sort, min, max, rating],
    () =>
      getProductsByCategory(
        "",
        "",
        page,
        perPage,
        search_string,
        sort,
        min,
        max,
        rating
      ),
    { keepPreviousData: true, staleTime: query_stale_time }
  );

  // Prefetch the next page!
  useEffect(() => {
    if (data?.data?.products?.hasNextPage) {
      queryClient.prefetchQuery(
        [
          "products",
          "",
          "",
          page + 1,
          perPage,
          search_string,
          sort,
          min,
          max,
          rating,
        ],
        () =>
          getProductsByCategory(
            "",
            "",
            page + 1,
            perPage,
            search_string,
            sort,
            min,
            max,
            rating
          )
      );
    }
  }, [data, page, queryClient]);

  console.log("categories", categories);

  return (
    <>
      <div>
        <div
          id="carouselExampleDark"
          className="carousel m-0 carousel-dark slide"
          data-bs-ride="carousel"
        >
          <div className="carousel-indicators">
            <button
              type="button"
              data-bs-target="#carouselExampleDark"
              data-bs-slide-to="0"
              className="active"
              aria-current="true"
              aria-label="Slide 1"
            ></button>
            <button
              type="button"
              data-bs-target="#carouselExampleDark"
              data-bs-slide-to="1"
              aria-label="Slide 2"
            ></button>
            <button
              type="button"
              data-bs-target="#carouselExampleDark"
              data-bs-slide-to="2"
              aria-label="Slide 3"
            ></button>
          </div>
          <div className="carousel-inner">
            <div className="carousel-item active" data-bs-interval="10000">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-lg-6 col-md-12 text-center col-12 align-self-center px-5">
                    <h4>Welcome to Surihatev</h4>
                    <h5>
                      Your One Stop Marketplace <br />
                      for Everyday Essentials
                    </h5>
                    <div
                      data-aos="fade-up"
                      data-aos-delay="500"
                      data-aos-easing="ease-in-sine"
                      style={{ zIndex: 5, position: "relative" }}
                    >
                      <div>
                        <a
                          href="https://surihatev.com/products"
                          className="btn btn1 growOnHover blockBtn "
                        >
                          <div>
                            <div>Shop Now</div>
                          </div>
                        </a>
                        &emsp;
                        <a
                          href="https://surihatev.com/surihatev/surihatevendor/"
                          className="btn btn2 blockBtn growOnHover "
                        >
                          <div>
                            <div>Become a Vendor</div>
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 d-lg-block d-md-none d-none col-md-12 col-12 pr-0">
                    <img
                      src="./images/bannerRight.png"
                      className="d-block w-100"
                      alt="..."
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="carousel-item" data-bs-interval="2000">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-lg-6 col-md-12 text-center col-12 align-self-center px-5">
                    <h4>Welcome to Surihatev</h4>
                    <h5>
                      Your One Stop Marketplace <br />
                      for Everyday Essentials
                    </h5>
                    <div
                      data-aos="fade-up"
                      data-aos-delay="500"
                      data-aos-easing="ease-in-sine"
                      style={{ zIndex: 5, position: "relative" }}
                    >
                      <div>
                        <a
                          href="https://surihatev.com/products"
                          className="btn btn1 growOnHover blockBtn "
                        >
                          <div>
                            <div>Shop Now</div>
                          </div>
                        </a>
                        &emsp;
                        <a
                          href="https://surihatev.com/surihatev/surihatevendor/"
                          className="btn btn2 blockBtn growOnHover "
                        >
                          <div>
                            <div>Become a Vendor</div>
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 d-lg-block d-md-none d-none col-md-12 col-12 pr-0">
                    <img
                      src="./images/bannerRight1.png"
                      className="d-block w-100"
                      alt="..."
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="carousel-item">
              <div className="container-fluid ">
                <div className="row">
                  <div className="col-lg-6 col-md-12 text-center col-12 align-self-center px-5">
                    <h4>Welcome to Surihatev</h4>
                    <h5>
                      Your One Stop Marketplace <br />
                      for Everyday Essentials
                    </h5>
                    <div
                      data-aos="fade-up"
                      data-aos-delay="500"
                      data-aos-easing="ease-in-sine"
                      style={{ zIndex: 5, position: "relative" }}
                    >
                      <div>
                        <a
                          href="https://surihatev.com/products"
                          className="btn btn1 growOnHover blockBtn "
                        >
                          <div>
                            <div>Shop Now</div>
                          </div>
                        </a>
                        &emsp;
                        <a
                          href="https://surihatev.com/surihatev/surihatevendor/"
                          className="btn btn2 blockBtn growOnHover "
                        >
                          <div>
                            <div>Become a Vendor</div>
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 d-lg-block d-md-none d-none col-md-12 col-12">
                    <img
                      src="./images/bannerRight2.png"
                      className="d-block w-100"
                      alt="..."
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <button
            className="carousel-control-prev"
            type="button"
            data-bs-target="#carouselExampleDark"
            data-bs-slide="prev"
          >
            <span
              className="carousel-control-prev-icon"
              aria-hidden="true"
            ></span>
            <span className="visually-hidden">Previous</span>
          </button>
          <button
            className="carousel-control-next"
            type="button"
            data-bs-target="#carouselExampleDark"
            data-bs-slide="next"
          >
            <span
              className="carousel-control-next-icon"
              aria-hidden="true"
            ></span>
            <span className="visually-hidden">Next</span>
          </button>
        </div>

        {/* <section className="mainU">
          <div className="container-fluid">
            <div className="row justify-content-center">
              <div className="col-lg-10">
                <div
                  className="row align-items-center"
                  style={{  }}
                >
                  <div className="col-xl-6 col-lg-7 col-md-12">
                  <div style={{zIndex: 2,position:'relative'}} data-aos="fade-right" data-aos-easing="ease-in-sine">
                    <h1 className="bannerHeading1 textCenter">Your One Stop Marketplace <br/>for Everyday Essentials</h1>
                    <h1 className="bannerHeading2 textCenter">
                      Welcome to Surihatev
                    </h1>
                    </div>
                    <br />
                    <div  data-aos="fade-up"
         data-aos-delay="500" data-aos-easing="ease-in-sine" style={{zIndex: 5,position:'relative'}}>
                    <div >
                    <a href="https://surihatev.com/products"  className="btn btn1 growOnHover blockBtn " >
                      <div>
                        <div>Shop Now</div>
                      </div>
                    </a>
                    &emsp;
                    <a href="https://surihatev.com/surihatev/surihatevendor/" className="btn btn2 blockBtn growOnHover ">
                      <div>
                        <div>Become a Vendor</div>
                      </div>
                    </a>
                    </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <img
            style={{
              width: "300px",
              position: "absolute",
              left: 0,
              top: 0,
              zIndex: 1,
              opacity: 0.8,
            }}
            src="images/bannerImg1.png"
            alt=""
            className="img-fluid hideOnPhone"
          />

          <img
            style={{
              width: "350px",
              position: "absolute",
              left: 0,
              bottom: 0,
              zIndex: 1,
              opacity: 0.8,
            }}
            src="images/bannerLeft.png"
            alt=""
            className="img-fluid"
          />

          <img
            style={{
              width: "400px",
              position: "absolute",
              right: 0,
              top: 0,
              zIndex: 1,
              opacity: 0.8,
            }}
            src="images/bannerRight22.png"
            alt=""
            className="img-fluid"
          />
        </section> */}

        {/* <section className="section2">
        <div style={{zIndex: 2}} data-aos="fade-right"
         data-aos-delay="500" data-aos-easing="ease-in-sine">
          <h1>Save Extra 5-10% on Every Auto Ship Order</h1>
          </div>
          &emsp;
          <img
            style={{
              width: "40px",
              transform: "rotate(55deg)",
            }}
            src="images/tomato.png"
            alt=""
            className="img-fluid"
          />
        </section> */}

        <section className="section3">
          <div className="row justify-content-center">
            <div className="col-xs-12 col-md-6 col-lg-5 col-xl-4">
              <div
                style={{ zIndex: 2, position: "relative" }}
                data-aos="fade-up"
                data-aos-delay="100"
                data-aos-easing="ease-in-sine"
              >
                <h3>Welcome To Surihatev</h3>

                <h1>
                  {
                    <>
                      We Care About Our Organic And Agriculture Products Growth
                    </>
                  }
                </h1>

                <p>
                  At Susquetech, we do more than sell products. Our expertise
                  and product catalog continues to expand, showing we are keen
                  to serve you in multiple industries. The mission statement of
                  our business is simple – to bring the best to our clients.
                </p>
              </div>
              <div
                style={{
                  textAlign: "center",
                  marginTop: "30px",
                  zIndex: 2,
                  position: "relative",
                }}
              >
                <Link to="/about" className="btn btn1 growOnHover ">
                  <div>
                    <div>Read More</div>
                  </div>
                </Link>
              </div>
            </div>
          </div>
          <img
            style={{
              width: "500px",
              position: "absolute",
              left: 0,
              top: 0,
              zIndex: 1,
              transform: "scaleX(-1)",
            }}
            src="images/section2Left.png"
            alt=""
            className="opacity5 img-fluid"
          />

          <img
            style={{
              width: "250px",
              position: "absolute",
              right: 0,
              bottom: 0,
              zIndex: 1,
            }}
            src="images/section2Right.png"
            alt=""
            className="img-fluid opacity5"
          />
        </section>

        <section className="section4">
          <div className="container-fluid">
            <div className="row justify-content-center">
              <div className="col-lg-10">
                <div className="row py-3">
                  <h1>Product Categories</h1>

                  <AliceCarousel
                    mouseTracking
                    responsive={responsive}
                    disableButtonsControls={true}
                    // disableDotsControls
                    controlsStrategy="alternate"
                    autoPlay
                    autoPlayInterval={1000}
                  >
                    {!isLoadingCategory &&
                      categories?.data?.categories.length > 0 &&
                      categories?.data?.categories?.map((item, index) => {
                        return (
                          <Link to={`/products/${item?._id}`}>
                            <div
                              style={{ zIndex: 2, margin: 10 }}
                              data-aos="flip-left"
                              data-aos-delay="100"
                              data-aos-easing="ease-in-sine"
                            >
                              <div key={index} style={{ padding: "20px" }}>
                                <div
                                  style={{
                                    width: "200px",
                                    height: "200px",
                                    backgroundColor: "#F5F5F5",
                                    borderRadius: "30px",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                  }}
                                >
                                  <img
                                    src={`${image_url}${item?.category_image}`}
                                    alt=""
                                    className="img-fluid"
                                    onError={(e) => {
                                      e.target.onerror = null;
                                      e.target.src = "img/testimonial.jpg";
                                    }}
                                    style={{ width: "100px" }}
                                  />
                                </div>
                                <div style={{ padding: "10px 20px" }}>
                                  <h3 className="productTitle">
                                    {item.name[0].toUpperCase() +
                                      item.name.slice(1).toLowerCase()}
                                  </h3>

                                  <span className="productCount">
                                    {item?.count || 0} Items{" "}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </Link>
                        );
                      })}
                  </AliceCarousel>
                </div>
              </div>
            </div>
          </div>
        </section>
        <br />

        <section className="section5">
          <div className="container-fluid">
            <div className="row justify-content-center">
              <div className="col-lg-10">
                <div className="row align-items-center ">
                  <div className="col-lg-6 text-lg-left text-center">
                    <div
                      style={{ zIndex: 2 }}
                      data-aos="zoom-in"
                      data-aos-delay="100"
                      data-aos-easing="ease-in-sine"
                    >
                      <div
                        style={{ width: "100%" }}
                        className="card1 growOnHover"
                      >
                        <div className="row">
                          <div className="col">
                            <h3 className="cardTitle1">Weekend Discount 20%</h3>
                            <h1 className="cardTitle2">Natural Eggs</h1>
                            <p className="cardText">
                              Lorem Ipsum is simply dummy
                            </p>

                            <Link to="/products" className="btn btn1 ">
                              <div>
                                <div>Shop Now</div>
                              </div>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 text-right position-relative">
                    <div
                      style={{ zIndex: 2 }}
                      data-aos="zoom-in"
                      data-aos-delay="200"
                      data-aos-easing="ease-in-sine"
                    >
                      <div
                        style={{ width: "100%" }}
                        className="card2 growOnHover"
                      >
                        <div className="row">
                          <div className="col">
                            <h3 className="cardTitle1">Weekend Discount 40%</h3>
                            <h1 className="cardTitle2">Taste The Best</h1>
                            <p className="cardText">
                              Lorem Ipsum is simply dummy
                            </p>

                            <Link to="/products" className="btn btn1 ">
                              <div>
                                <div>Shop Now</div>
                              </div>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                    <br />
                    <div
                      style={{ zIndex: 2 }}
                      data-aos="zoom-in"
                      data-aos-delay="200"
                      data-aos-easing="ease-in-sine"
                    >
                      <div
                        style={{ width: "100%" }}
                        className="card3 growOnHover"
                      >
                        <div className="row">
                          <div className="col">
                            <h3 className="cardTitle1">Weekend Discount 20%</h3>
                            <h1 className="cardTitle2">Ditch The Junk</h1>
                            <p className="cardText">
                              Lorem Ipsum is simply dummy
                            </p>

                            <Link to="/products" className="btn btn1 ">
                              <div>
                                <div>Shop Now</div>
                              </div>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="section6">
          <div className="container-fluid">
            <div className="row justify-content-center">
              <div className="col-lg-10">
                <div className="row py-3">
                  <div className="col">
                    <h1 className="cardTitle2" style={{ textAlign: "center" }}>
                      Best Sellers
                    </h1>
                    <p className="cardText" style={{ textAlign: "center" }}>
                      Do not miss the current offers until the end of March.
                    </p>
                  </div>
                  {/* <div className="col-lg-6 text-right">
                    <Link to="/products" className="btn btn-primary">
                      View All Products
                      <i className="fas fa-long-arrow-alt-right ml-2" />
                    </Link>
                  </div> */}
                </div>
                <div className="row py-3" style={{ justifyContent: "center" }}>
                  <div className="col-xs-12 col-lg-11 col-xl-10">
                    <div className="row">
                      {isLoading ? (
                        <>
                          <div className="col-lg-3 col-sm-12 col-xs-12 mt-10 mb-10">
                            <ProductCardSkeleton />
                          </div>
                          <div className="col-lg-3 col-sm-12 col-xs-12 mt-10 mb-10">
                            <ProductCardSkeleton />
                          </div>
                          <div className="col-lg-3 col-sm-12 col-xs-12 mt-10 mb-10">
                            <ProductCardSkeleton />
                          </div>
                          <div className="col-lg-3 col-sm-12 col-xs-12 mt-10 mb-10">
                            <ProductCardSkeleton />
                          </div>
                        </>
                      ) : data?.data?.products?.docs?.length === 0 ? (
                        <div className="col-12 mt-10 mb-10">
                          <h1>No Items Found</h1>
                        </div>
                      ) : (
                        data?.data?.products?.docs?.map((product) => (
                          <ProductCard key={product._id} product={product} />
                        ))
                      )}
                    </div>
                  </div>
                </div>
                {/* ROW END */}
                <div className="row mt-10" style={{ justifyContent: "center" }}>
                  <Link to="/products" className="btn btn1 ">
                    <div>
                      <div>View All</div>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="section7">
          <div className="container-fluid">
            <div className="row justify-content-center">
              <div className="col-lg-10">
                <div className="row align-items-center ">
                  <div className="col text-lg-left text-center">
                    <div
                      style={{ zIndex: 2 }}
                      data-aos="zoom-in-up"
                      data-aos-delay="200"
                      data-aos-easing="ease-in-sine"
                    >
                      <div
                        style={{ width: "100%" }}
                        className="card7 growOnHover"
                      >
                        <div className="overlay"></div>

                        <div
                          className="row"
                          style={{
                            padding: "50px",
                            zIndex: 2,
                            position: "relative",
                          }}
                        >
                          <div className="col">
                            <h3 className="cardTitle1 textCenter">
                              Weekend Discount 50%
                            </h3>
                            <h1 className="cardTitle2 textCenter">
                              Cookie And Ice Cream
                            </h1>
                            <p className="cardText textCenter">
                              Lorem Ipsum is simply dummy
                            </p>

                            <Link
                              to="/products"
                              className="btn btn1 textCenter "
                            >
                              <div>
                                <div>Shop Now</div>
                              </div>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="section8">
          <div className="container-fluid">
            <div className="row justify-content-center">
              <div className="col-lg-7">
                <div className="row align-items-center justify-space-between ">
                  <div className="col-xs-12 col-lg-7 text-lg-left text-center">
                    <div
                      style={{ width: "100%", padding: "20px 40px 20px 20px" }}
                    >
                      <div className="row">
                        <div className="col">
                          {/* <h3 className="cardTitle1">$20 discount for your first order</h3> */}
                          <div
                            style={{ zIndex: 2 }}
                            data-aos="zoom-in-up"
                            data-aos-delay="100"
                            data-aos-easing="ease-in-sine"
                          >
                            <h1 className="cardTitle2 textCenter">
                              Download App on Mobile:
                            </h1>
                            <p
                              className="cardText"
                              style={{ fontSize: "20px" }}
                            >
                              Available on both IOS & Android
                            </p>
                          </div>
                          <br />
                          <div className="row">
                            <div className="col-sm-12 col-md-6 col-lg-4 py-2">
                              <a href="https://apps.apple.com/pk/app/surihatev-marketplace/id6458189288">
                                <img
                                  src="images/apple.png"
                                  alt=""
                                  className="btnImage"
                                />
                              </a>
                            </div>
                            <div className="col-sm-12 col-md-6 col-lg-4 py-2">
                              <a href="https://play.google.com/store/apps/details?id=com.uaapps.twosisters&hl=en&gl=US">
                                <img
                                  src="images/google.png"
                                  alt=""
                                  className="btnImage"
                                />
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xs-12 col-lg-4 text-lg-left text-center">
                    <div
                      style={{ zIndex: 2 }}
                      data-aos="zoom-in-up"
                      data-aos-delay="200"
                      data-aos-easing="ease-in-sine"
                    >
                      <div className="card8">
                        <img
                          src="images/card8.png"
                          alt=""
                          className="card8Image"
                        />
                      </div>

                      {/* <div
                      style={{ width: "100%", height: "50px" }}
                      className="card8"
                    >
                      
                    </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      {/* <Section1 />
      <Section2 />
      <Section3 />
      <Section4 />
      <Section5 />
      <Section6 />
      <Section7 />
      <Section8 />
      <Section9 />
      <Section10 /> */}
    </>
  );
}

// <div className="col-lg-4 col-sm-6 my-2">
// <a href>
//   <div className="productCardU">
//     <div className="productImgU position-relative">
//       <img
//         src="images/product/2.png"
//         alt=""
//         className="img-fluid"
//       />
//       <div className="overlayHover">
//         <button className="btn btn-bag">
//           <img
//             src="images/icons/bag.png"
//             alt=""
//             className="img-fluid"
//           />
//         </button>
//       </div>
//     </div>
//     <div className="productDescU py-2 text-center">
//       <h5>Pet Accessories</h5>
//       <h3>$20.00</h3>
//     </div>
//   </div>
//   {/* productCardU */}
// </a>
// </div>
// {/* COL-LG-4 END */}
// <div className="col-lg-4 col-sm-6 my-2">
// <a href>
//   <div className="productCardU">
//     <div className="productImgU position-relative">
//       <div className="badgeNew">New</div>
//       <img
//         src="images/product/3.png"
//         alt=""
//         className="img-fluid"
//       />
//       <div className="overlayHover">
//         <button className="btn btn-bag">
//           <img
//             src="images/icons/bag.png"
//             alt=""
//             className="img-fluid"
//           />
//         </button>
//       </div>
//     </div>
//     <div className="productDescU py-2 text-center">
//       <h5>Pet Accessories</h5>
//       <h3>$20.00</h3>
//     </div>
//   </div>
//   {/* productCardU */}
// </a>
// </div>
// {/* COL-LG-4 END */}
// <div className="col-lg-4 col-sm-6 my-2">
// <a href="#">
//   <div className="productCardU">
//     <div className="productImgU position-relative">
//       <div className="badgeNew">New</div>
//       <img
//         src="images/product/1.png"
//         alt=""
//         className="img-fluid"
//       />
//       <div className="overlayHover">
//         <button className="btn btn-bag">
//           <img
//             src="images/icons/bag.png"
//             alt=""
//             className="img-fluid"
//           />
//         </button>
//       </div>
//     </div>
//     <div className="productDescU py-2 text-center">
//       <h5>Pet Accessories</h5>
//       <h3>$20.00</h3>
//     </div>
//   </div>
//   {/* productCardU */}
// </a>
// </div>
// {/* COL-LG-4 END */}
// <div className="col-lg-4 col-sm-6 my-2">
// <a href="#">
//   <div className="productCardU">
//     <div className="productImgU position-relative">
//       <img
//         src="images/product/2.png"
//         alt=""
//         className="img-fluid"
//       />
//       <div className="overlayHover">
//         <button className="btn btn-bag">
//           <img
//             src="images/icons/bag.png"
//             alt=""
//             className="img-fluid"
//           />
//         </button>
//       </div>
//     </div>
//     <div className="productDescU py-2 text-center">
//       <h5>Pet Accessories</h5>
//       <h3>$20.00</h3>
//     </div>
//   </div>
//   {/* productCardU */}
// </a>
// </div>
// {/* COL-LG-4 END */}
// <div className="col-lg-4 col-sm-6 my-2">
// <a href>
//   <div className="productCardU">
//     <div className="productImgU position-relative">
//       <div className="badgeNew">New</div>
//       <img
//         src="images/product/3.png"
//         alt=""
//         className="img-fluid"
//       />
//       <div className="overlayHover">
//         <button className="btn btn-bag">
//           <img
//             src="images/icons/bag.png"
//             alt=""
//             className="img-fluid"
//           />
//         </button>
//       </div>
//     </div>
//     <div className="productDescU py-2 text-center">
//       <h5>Pet Accessories</h5>
//       <h3>$20.00</h3>
//     </div>
//   </div>
//   {/* productCardU */}
// </a>
// </div>
