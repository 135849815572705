import React, { useState, useEffect } from "react";
import "./HowToUse.css";
import AOS from 'aos';
import 'aos/dist/aos.css'
import { image_url } from "../../Util/connection_strings";

export default function Gallery() {

  useEffect(() => {
    AOS.init({ disable: 'mobile', mirror: true });
    AOS.refresh();
  }, []);

  return (<>
    <section className="bg-about">
      <img
        style={{
          width: "400px",
          position: "absolute",
          left: 0,
          bottom: 0,
          zIndex: 1,
          opacity: 0.8,
        }}
        src="images/bannerLeft.png"
        alt=""
        className="img-fluid"
      />

      <img
        style={{
          width: "400px",
          position: "absolute",
          right: 0,
          top: 0,
          zIndex: 1,
          opacity: 0.8,
        }}
        src="images/bannerRight22.png"
        alt=""
        className="img-fluid"
      />
      <div className="container">
        <div className="row">
          <div className="col-lg-12 text-center">
            <div style={{ zIndex: 2 }} data-aos="fade-up"
              data-aos-delay="0" data-aos-easing="ease-in-sine">
              <h1 className="bannerHeading2">How To Use</h1>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="p-55-0">
      <div class="container">
        <div class="row">
          <div style={{ zIndex: 2 }} data-aos="fade-up"
            data-aos-delay="200" data-aos-easing="ease-in-sine">
            <div>
              <video width="100%" height="500" controls>
                <source src={image_url + "uploads/Signup Animation Rev 01-1706037694.mp4"} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
          </div>
        </div>
      </div>
    </section>
  </>
  );
}
