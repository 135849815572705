import React from 'react'
import { useEffect } from "react";

import AOS from 'aos';
import 'aos/dist/aos.css'


function About() {

  useEffect(() => {
    AOS.init({disable: 'mobile',mirror:true});
    AOS.refresh();
  }, []);



  return (<>
     <section className="bg-about">
      <img
            style={{
              width: "400px",
              position: "absolute",
              left: 0,
              bottom: 0,
              zIndex: 1,
              opacity: 0.8,
            }}
            src="images/bannerLeft.png"
            alt=""
            className="img-fluid"
          />

          <img
            style={{
              width: "400px",
              position: "absolute",
              right: 0,
              top: 0,
              zIndex: 1,
              opacity: 0.8,
            }}
            src="images/bannerRight22.png"
            alt=""
            className="img-fluid"
          />
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-center">
            <div style={{zIndex: 2}} data-aos="fade-up"
         data-aos-delay="200" data-aos-easing="ease-in-sine">
              <h1 className="bannerHeading2">About Us</h1>
              </div>
            </div>
          </div>
        </div>
      </section>
      
      <section className="section8">
          <div className="container-fluid">
            <div className="row justify-content-center">
              <div className="col-lg-8">
                <div className="row align-items-center ">
                  <div className="col-xs-12 col-lg-6 text-lg-left text-center">
                    <div
                      style={{ width: "100%", padding:"20px 40px 20px 20px" }}
                    >
                      <div className="row">
                      <div style={{zIndex: 2}} data-aos="fade-left"
         data-aos-delay="200" data-aos-easing="ease-in-sine">
                        <div className="col">
                          <h1 className="cardTitle2">About Us</h1>
                          <h3 className="cardTitle1">Online shopping of a new kind</h3>
                          <p className="cardText">
                          At Susquetech, we do more than sell products. Our expertise and product catalog continues to expand, showing we are keen to serve you in multiple industries. The mission statement of our business is simple – to bring the best to our clients. Carrying forward the vision of our visionary owner, Mr. Frederick Kutubebi, we intend to create space for our business in an intensely competitive market. <br></br>
                          <br></br>Gradually, we have plans to expand our portfolio and expand our reach to other markets. Our website will soon be home to some of the best technological products that money can buy. Likewise, customers can purchase farm fresh meat and healthy products from us.
                          </p>
                          <br/>
                        </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xs-12 col-lg-6 text-lg-left text-center">
                    <div
                      style={{ width: "100%", height: "50px" }}
                      className="card9"
                    >
                      
                    </div>
                  </div>
                
                </div>
              </div>
            </div>
          </div>
        </section>
      
        <section className="section8">
          <div className="container-fluid">
            <div className="row justify-content-center">
              <div className="col-lg-8">
                <div className="row align-items-center ">
                  
                  <div className="col-xs-12 col-lg-6 text-lg-left text-center">
                    <div
                      style={{ width: "100%", height: "50px" }}
                      className="card10"
                    >
                      
                    </div>
                  </div>
                  <div className="col-xs-12 col-lg-6 text-lg-left text-center">
                    <div
                      style={{ width: "100%", padding:"20px 40px 20px 20px" }}
                    >
                      <div className="row">
                      <div style={{zIndex: 2}} data-aos="fade-right"
         data-aos-delay="200" data-aos-easing="ease-in-sine">
                        <div className="col">
                          <h1 className="cardTitle2">Our Story</h1>
                          <h3 className="cardTitle1">Connect With Me, And Let’s Get It Done</h3>
                          <p className="cardText">
                          Welcome to Susquehanna River Haven Techlinks Ventures, a premium source of all types of meat products. Our meat products reach your farm fresh every time you order from us. We offer a huge variety to choose from for our valued customers. We take excellent care at every step and ensure the quickest, safest delivery every time.
Our deliveries are consistently prepared and timely delivered. At Susquehanna River Haven, we take pride in becoming retail and wholesale suppliers of choice for our valued clients. Whether you are an individual or a business owner, our products reach you in the shortest time possible.
                          </p>
                                                 </div>
                                                 </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="section8">
          <div className="container-fluid">
            <div className="row justify-content-center">
              <div className="col-lg-8">
                <div className="row align-items-center ">
                  
              
                  <div className="col-xs-12 col-lg-12 text-lg-left text-center">
                    <div
                      style={{ width: "100%", padding:"20px 40px 20px 20px" }}
                    >
                      <div className="row">
                        <div className="col">
                          
             
                          <h3 className="cardTitle2 text-center">One Stop Solution For All</h3>
                          <p className="cardText text-center">
                          Once active, Susquetech will redefine the way you buy products and services online. For us, Susquetech is destined to succeed because we have it all worked out. Everything from customer experience, products portfolios to online support will be at your fingertips. You can always log in to the website and order your favorite product.
                          </p>
                          <br/>
                          <h3 className="cardTitle2 text-center">Serving Different Industries</h3>
                          <p className="cardText text-center">
                          Visit the site and find any product that you desire. Here, you can order your favorite piece of pork. Looking for a reputed cold storage spot? We have you covered. Just visit the site, or get in touch with our support staff. They’ll explain the procedure of renting cold storage space for your purpose. With cutting edge storage facilities available, ordering a storage facility from Susquetech is the right choice.<br></br>
                          As we continue to expand our market footprint, we will also expand our product portfolio. Soon, you will find your favorite electronic products, gadgets, and phones, all under one roof. Our site is designed to provide utmost comfort and easy access to all our products. Despite the expansion, this trademark feature will continue. Visit the site, explore categories of products and choose your favorite product. Visit the checkout section, pay the amount, and you are good to go.<br></br>With Susquetech, purchasing your favorite stuff online was never easier.
Sign up now, and start exploring our products already.




                          </p>
                          <br/>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      
      </>
   
  )
}

export default About