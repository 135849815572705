import React from "react";
import { Link } from "react-router-dom";
import {FaTelegramPlane,FaFacebookF,FaTwitter} from "react-icons/fa"
import {BiLogoInstagramAlt} from "react-icons/bi"
import cards from "../assets/cards.png"



export default function Footer() {
  return (
    <>
      <div>
        <section className="footer">
          <div className="container">
            <div className="row">
              <div className="col-lg-2 order-1">
              <h1 className="footerTitle1">Useful Links</h1>

        

              <ul className="pl-0 mt-4 footerList">
                      <li>
                       
                        <Link to="/"><p> <span className="dot"/> &emsp;Home</p></Link>
                      </li>
                      <li>
                        <Link to="/products"><p><span className="dot"/> &emsp;Shop</p></Link>
                      </li>
                      <li>
                        <Link to="/about"><p><span className="dot"/> &emsp;About Us</p></Link>
                      </li>
       
                      <li>
                        <Link to="/contact-us"><p><span className="dot"/> &emsp;Contact</p></Link>
                      </li>
                    </ul>


              

              </div>
              <div className="col-lg-7 px-5 order-lg-2 order-md-3 order-sm-3 order-3">
              <div className="row">
                        <div className="col">
                          <h3 className="footerTitle2">Newsletter Signup</h3>
                          <p className="footerText">  
                          First Time Subscribe Will be sent a code for 15% off a future purchase
                          </p>
                         
                          <div class="fotr-input-2">
                    <input type="text" class="form-control" placeholder="Email Address here..." aria-describedby=""/>
                    
                    
                            <div className="btn btn1 " >
                            <FaTelegramPlane/>
                            </div>
                    
                </div>
                <br/>
                <div className="row justify-content-center">
                <div className="socialBtn ">
                <FaFacebookF/>
                </div>
                &emsp;
                <div className="socialBtn ">
                <FaTwitter/>
                </div>
                &emsp;
                <div className="socialBtn ">
                <BiLogoInstagramAlt/>
                </div>

                </div>
                        </div>
                      </div>
              </div>
              <div className="col-lg-3 order-lg-3 order-md-2 order-sm-2 order-2">
              <h1 className="footerTitle1">Customer Care</h1>

        

              <ul className="pl-0 mt-4 footerList">
                      <li>
                       
                        <Link to="/"><p> <span className="dot"/> &emsp;Returns</p></Link>
                      </li>
                      <li>
                        <Link to="/products"><p><span className="dot"/> &emsp;Terms &</p></Link>
                      </li>
                      <li>
                        <Link to="/products"><p><span className="dot"/> &emsp;Conditions</p></Link>
                      </li>
                      <li>
                        <Link to="/privacy-policy"><p><span className="dot"/> &emsp;Privacy Policy</p></Link>
                      </li>
                      {/* <li>
                      <Link to="/about"><p><span className="dot"/> &emsp;Faq's</p></Link>
                      </li> */}
                     
                    </ul>


              

              </div>
            </div>
          </div>
        </section>
        <section className="footerBar">
          <div className="container-fluid">
            <div className="row justify-content-center">
              <div className=" col-xs-10 col-lg-10">
              <div className="row pt-3 justify-space-between footerRow">
            
            <div className="col">
            <p className>
            Copyright {new Date().getFullYear()} © surihatev.com. All rights reserved.
            </p>
            </div>
            <div className="col text-right footerCards">

            <img src={cards} height="30px"/>
           
          
          </div>
        </div>
              </div>
            </div>
           
          </div>
        </section>
        
      </div>
     
  
    </>
  );
}
