import React, { useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { Link } from "react-router-dom";
import ImagesSlick from "../../Components/ImagesSlick";
import LoadingView from "../../Components/LoadingView";
import ProductCard from "../../Components/ProductCard";
import ProductCardSkeleton from "../../Components/SkeletonCards/ProductCard";
import ReviewCard from "../../Components/ReviewCard";
import {
  addItemToCart,
  getFirstWordOfEveryLetter,
  query_stale_time,
} from "../../Helpers/Helpers";
import StarRatings from "react-star-ratings";
import { image_url } from "../../Util/connection_strings";
import {
  addItemWishlist,
  getProductDetailsLoggedIn,
  getProductDetails,
} from "../../Apis";
import Success from "../../Components/Modal.Success";
import Error from "../../Components/Modal.Error";
import { useRecoilValue } from "recoil";
import { userState } from "../../Recoil";
import Swal from "sweetalert2";
import Slider from "react-slick";
import moment from "moment";
import { addRating, editRating, getRatings } from "../../Apis/Rating";

const selected = {
  backgroundColor: "#145B3D",
  color: "white",
  cursor: "pointer",
};

let Numbers = ["First", "Second", "Third", "Fourth", "Fifth"];

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
};

export default function ProductDetails({ match }) {
  const user = useRecoilValue(userState);
  const [quantity, setQuantity] = useState(1);
  const [rating, setRating] = useState(0);
  const [selected_variation, setVariation] = useState({});
  const [comment, setComment] = useState("");
  const [review_id, setReviewId] = useState("");
  const [review_created_at, setReviewCreatedAt] = useState("");

  const [model, setModel] = useState("");
  const [avatar_type, setAvatarType] = useState("");
  const [no_of_pets, setNoOfPets] = useState(1);
  const [avatar_Custom_details, setCustomAvatarDetails] = useState([
    {
      pets_breed: "",
      pet_name: "",
      image: "",
    },
  ]);

  const [avatar_preDrawn_details, setPreDrawnAvatarDetails] = useState([
    {
      pets_breed: "",
      pet_name: "",
      image: "",
    },
  ]);
  useEffect(() => {
    let array = [];
    for (let i = 1; i <= no_of_pets; i++) {
      array.push({
        pets_breed: "",
        pet_name: "",
        image: "",
      });
    }
    setPreDrawnAvatarDetails([...array]);
  }, [no_of_pets]);

  const { mutate } = useMutation((id) => addItemWishlist(id), {
    onSuccess: (res) => {
      Success(res?.data?.message);
      refetch();
    },
    onError: (err) => Error(err?.response?.data?.message),
  });

  const { mutate: add_rating_mutate } = useMutation(
    (data) => (review_id ? editRating(data) : addRating(data)),
    {
      onSuccess: (res) => {
        Success(res?.data?.message);
        refetch_ratings();
      },
      onError: (err) => Error(err?.response?.data?.message),
    }
  );

  const { isLoading, data, refetch } = useQuery(
    ["product", match?.params?.id, user],
    () =>
      match?.params?.id && user?._id
        ? getProductDetailsLoggedIn(match?.params?.id)
        : getProductDetails(match?.params?.id),
    { staleTime: query_stale_time }
  );

  const {
    isLoading: ratings_loading,
    data: ratings,
    refetch: refetch_ratings,
  } = useQuery(
    ["ratings", match?.params?.id],
    () => match?.params?.id && getRatings(match?.params?.id),
    { staleTime: query_stale_time }
  );

  useEffect(() => {
    if (data?.data?.product.my_rating) {
      setRating(data?.data?.product.my_rating?.rating);
      setComment(data?.data?.product.my_rating?.comment);
      setReviewId(data?.data?.product.my_rating?._id);
      setReviewCreatedAt(data?.data?.product.my_rating?.createdAt);
    }
  }, [data?.data?.product]);

  if (isLoading) return <LoadingView />;

  const handleDataChange = (e, index) => {
    let data = [...avatar_preDrawn_details];
    data[index][e.target.name] = e.target.value;
    setPreDrawnAvatarDetails(data);
  };

  const handleCustomChange = (e, index) => {
    if (e.target.name == "image") {
      let data = [...avatar_Custom_details];
      data[index][e.target.name] = e.target.files[0];

      setCustomAvatarDetails(data);
    } else {
      let data = [...avatar_Custom_details];
      data[index][e.target.name] = e.target.value;

      setCustomAvatarDetails(data);
    }
  };

  const PreDrawnOrderUI = () => {
    return avatar_preDrawn_details.length > 0
      ? avatar_preDrawn_details?.map((item, index) => (
          <>
            <div className="d-flex flex-row mb-4 ">
              <div className="pl-2 pr-2 bd-highlight select-size">
                <div
                  className="gpo-element gpo-form__group gpo-col-12 show-addon-for-input has-success"
                  data-element-id="select-14"
                  data-clo-display="hide"
                >
                  <div className="gpo-hidden-data">
                    <input
                      type="hidden"
                      data-field-name="First Pet Breed"
                      defaultValue
                    />
                  </div>
                  <label className="gpo-label" htmlFor="select-14">
                    <span className="label-content">
                      {" "}
                      {Numbers[index]} Pet's Breed
                    </span>
                    <span className="label-required">*</span>
                  </label>
                  <div className="gpo-select">
                    <select
                      data-type="select"
                      className="gpo-select"
                      id={`select-${index}`}
                      name="pets_breed"
                      onChange={(e) => handleDataChange(e, index)}
                    >
                      <option selected disabled="disabled" value>
                        Choose Your Pet's Breed
                      </option>
                      <option value="Afghan Hound 1">Afghan Hound 1</option>
                      <option value="Afghan Hound 2">Afghan Hound 2</option>
                      <option value="Airedale 1">Airedale 1</option>
                      <option value="Airedale 2">Airedale 2</option>
                      <option value="Akita Inu 1">Akita Inu 1</option>
                      <option value="Akita Inu 2">Akita Inu 2</option>
                      <option value="Alaskan Malamute 1">
                        Alaskan Malamute 1
                      </option>
                      <option value="Alaskan Malamute 2">
                        Alaskan Malamute 2
                      </option>
                      <option value="American Akita 1">American Akita 1</option>
                      <option value="American Akita 2">American Akita 2</option>
                      <option value="American Bulldog 1">
                        American Bulldog 1
                      </option>
                      <option value="American Bulldog 2">
                        American Bulldog 2
                      </option>
                      <option value="American Eskimo 1">
                        American Eskimo 1
                      </option>
                      <option value="American Eskimo 2">
                        American Eskimo 2
                      </option>
                      <option value="American Foxhound 1">
                        American Foxhound 1
                      </option>
                      <option value="American Foxhound 2">
                        American Foxhound 2
                      </option>
                      <option value="Aussiedoodle 1">Aussiedoodle 1</option>
                      <option value="Aussiedoodle 2">Aussiedoodle 2</option>
                      <option value="Australian Cattle Dog 1 / Blue Heeler 1">
                        Australian Cattle Dog 1 / Blue Heeler 1
                      </option>
                      <option value="Australian Cattle Dog 2 / Blue Heeler 2">
                        Australian Cattle Dog 2 / Blue Heeler 2
                      </option>
                      <option value="Australian Kelpie 1">
                        Australian Kelpie 1
                      </option>
                      <option value="Australian Kelpie 1">
                        Australian Kelpie 1
                      </option>
                      <option value="Australian Shepherd 1">
                        Australian Shepherd 1
                      </option>
                      <option value="Australian Shepherd 2">
                        Australian Shepherd 2
                      </option>
                      <option value="Basenji 1">Basenji 1</option>
                      <option value="Basenji 2">Basenji 2</option>
                      <option value="Basset Hound 1">Basset Hound 1</option>
                      <option value="Basset Hound 2">Basset Hound 2</option>
                      <option value="Beagle 1">Beagle 1</option>
                      <option value="Beagle 2">Beagle 2</option>
                      <option value="Bearded Collie 1">Bearded Collie 1</option>
                      <option value="Bearded Collie 2">Bearded Collie 2</option>
                      <option value="Belgian Malinois 1">
                        Belgian Malinois 1
                      </option>
                      <option value="Belgian Malinois 2">
                        Belgian Malinois 2
                      </option>
                      <option value="Bernedoodle 1">Bernedoodle 1</option>
                      <option value="Bernedoodle 2">Bernedoodle 2</option>
                      <option value="Bernese Mountain Dog 1">
                        Bernese Mountain Dog 1
                      </option>
                      <option value="Bernese Mountain Dog 2">
                        Bernese Mountain Dog 2
                      </option>
                      <option value="Bichon Frise 1">Bichon Frise 1</option>
                      <option value="Bichon Frise 2">Bichon Frise 2</option>
                      <option value="Biewer Terrier 1">Biewer Terrier 1</option>
                      <option value="Biewer Terrier 2">Biewer Terrier 2</option>
                      <option value="Blue Heeler 1 / Australian Cattle Dog 1">
                        Blue Heeler 1 / Australian Cattle Dog 1
                      </option>
                      <option value="Blue Heeler 2 / Australian Cattle Dog 2">
                        Blue Heeler 2 / Australian Cattle Dog 2
                      </option>
                      <option value="Border Collie 1">Border Collie 1</option>
                      <option value="Border Collie 2">Border Collie 2</option>
                      <option value="Border Terrier 1">Border Terrier 1</option>
                      <option value="Border Terrier 2">Border Terrier 2</option>
                      <option value="Borzoi 1">Borzoi 1</option>
                      <option value="Borzoi 2">Borzoi 2</option>
                      <option value="Boston Terrier 1">Boston Terrier 1</option>
                      <option value="Boston Terrier 2">Boston Terrier 2</option>
                      <option value="Bouvier des Flandres 1">
                        Bouvier des Flandres 1
                      </option>
                      <option value="Bouvier des Flandres 2">
                        Bouvier des Flandres 2
                      </option>
                      <option value="Boxer 1">Boxer 1</option>
                      <option value="Boxer 2">Boxer 2</option>
                      <option value="Boykin Spaniel 1">Boykin Spaniel 1</option>
                      <option value="Boykin Spaniel 2">Boykin Spaniel 2</option>
                      <option value="Brittany 1">Brittany 1</option>
                      <option value="Brittany 2">Brittany 2</option>
                      <option value="Brussels Griffon 1">
                        Brussels Griffon 1
                      </option>
                      <option value="Brussels Griffon 2">
                        Brussels Griffon 2
                      </option>
                      <option value="Bull Terrier 1">Bull Terrier 1</option>
                      <option value="Bull Terrier 2">Bull Terrier 2</option>
                      <option value="Bulldog 1">Bulldog 1</option>
                      <option value="Bulldog 2">Bulldog 2</option>
                      <option value="Bullmastiff 1">Bullmastiff 1</option>
                      <option value="Bullmastiff 2">Bullmastiff 2</option>
                      <option value="Cairn Terrier 1">Cairn Terrier 1</option>
                      <option value="Cairn Terrier 2">Cairn Terrier 2</option>
                      <option value="Cane Corso 1">Cane Corso 1</option>
                      <option value="Cane Corso 2">Cane Corso 2</option>
                      <option value="Cavachon 1">Cavachon 1</option>
                      <option value="Cavachon 2">Cavachon 2</option>
                      <option value="Cavalier King Charles Spaniel 1">
                        Cavalier King Charles Spaniel 1
                      </option>
                      <option value="Cavalier King Charles Spaniel 2">
                        Cavalier King Charles Spaniel 2
                      </option>
                      <option value="Chihuahua 1">Chihuahua 1</option>
                      <option value="Chihuahua 2">Chihuahua 2</option>
                      <option value="Chihuahua Long-Haired 1">
                        Chihuahua Long-Haired 1
                      </option>
                      <option value="Chihuahua Long-Haired 2">
                        Chihuahua Long-Haired 2
                      </option>
                      <option value="Chinese Crested 1">
                        Chinese Crested 1
                      </option>
                      <option value="Chinese Crested 2">
                        Chinese Crested 2
                      </option>
                      <option value="Chow Chow 1">Chow Chow 1</option>
                      <option value="Chow Chow 2">Chow Chow 2</option>
                      <option value="Cockapoo 1">Cockapoo 1</option>
                      <option value="Cockapoo 2">Cockapoo 2</option>
                      <option value="Corgi Cardigan 1">Corgi Cardigan 1</option>
                      <option value="Corgi Cardigan 2">Corgi Cardigan 2</option>
                      <option value="Corgi Pembroke 1">Corgi Pembroke 1</option>
                      <option value="Corgi Pembroke 2">Corgi Pembroke 2</option>
                      <option value="Coton de Tulear 1">
                        Coton de Tulear 1
                      </option>
                      <option value="Coton de Tulear 2">
                        Coton de Tulear 2
                      </option>
                      <option value="Dachshund 1">Dachshund 1</option>
                      <option value="Dachshund 2">Dachshund 2</option>
                      <option value="Dachshund Long Haired 1">
                        Dachshund Long Haired 1
                      </option>
                      <option value="Dachshund Long-Haired 2">
                        Dachshund Long-Haired 2
                      </option>
                      <option value="Dalmatian 1">Dalmatian 1</option>
                      <option value="Dalmatian 2">Dalmatian 2</option>
                      <option value="Doberman 1">Doberman 1</option>
                      <option value="Doberman 2">Doberman 2</option>
                      <option value="Doberman Floppy Ears 1">
                        Doberman Floppy Ears 1
                      </option>
                      <option value="Doberman Floppy Ears 2">
                        Doberman Floppy Ears 2
                      </option>
                      <option value="Dogo Argentino 1">Dogo Argentino 1</option>
                      <option value="Dogo Argentino 2">Dogo Argentino 2</option>
                      <option value="Dogue de Bordeaux 1">
                        Dogue de Bordeaux 1
                      </option>
                      <option value="Dogue de Bordeaux 2">
                        Dogue de Bordeaux 2
                      </option>
                      <option value="English Cocker Spaniel 1">
                        English Cocker Spaniel 1
                      </option>
                      <option value="English Cocker Spaniel 2">
                        English Cocker Spaniel 2
                      </option>
                      <option value="English Setter 1">English Setter 1</option>
                      <option value="English Setter 2">English Setter 2</option>
                      <option value="English Springer Spaniel 1">
                        English Springer Spaniel 1
                      </option>
                      <option value="English Springer Spaniel 2">
                        English Springer Spaniel 2
                      </option>
                      <option value="Finnish Lapphund 1">
                        Finnish Lapphund 1
                      </option>
                      <option value="Finnish Lapphund 2">
                        Finnish Lapphund 2
                      </option>
                      <option value="Fox Terrier Smooth Coat 1">
                        Fox Terrier Smooth Coat 1
                      </option>
                      <option value="Fox Terrier Smooth Coat 2">
                        Fox Terrier Smooth Coat 2
                      </option>
                      <option value="Fox Terrier Wire Coat 1">
                        Fox Terrier Wire Coat 1
                      </option>
                      <option value="Fox Terrier Wire Coat 2">
                        Fox Terrier Wire Coat 2
                      </option>
                      <option value="French Bulldog 1">French Bulldog 1</option>
                      <option value="French Bulldog 2">French Bulldog 2</option>
                      <option value="German Shepherd 1">
                        German Shepherd 1
                      </option>
                      <option value="German Shepherd 2">
                        German Shepherd 2
                      </option>
                      <option value="German Shorthaired Pointer 1">
                        German Shorthaired Pointer 1
                      </option>
                      <option value="German Shorthaired Pointer 2">
                        German Shorthaired Pointer 2
                      </option>
                      <option value="German Spitz 1">German Spitz 1</option>
                      <option value="German Spitz 2">German Spitz 2</option>
                      <option value="Giant Schnauzer 1">
                        Giant Schnauzer 1
                      </option>
                      <option value="Giant Schnauzer 2">
                        Giant Schnauzer 2
                      </option>
                      <option value="Goldendoodle 1">Goldendoodle 1</option>
                      <option value="Goldendoodle 2">Goldendoodle 2</option>
                      <option value="Golden Retriever 1">
                        Golden Retriever 1
                      </option>
                      <option value="Golden Retriever 2">
                        Golden Retriever 2
                      </option>
                      <option value="Gordon Setter 1">Gordon Setter 1</option>
                      <option value="Gordon Setter 2">Gordon Setter 2</option>
                      <option value="Great Dane 1">Great Dane 1</option>
                      <option value="Great Dane 2">Great Dane 2</option>
                      <option value="Great Pyrenees 1">Great Pyrenees 1</option>
                      <option value="Great Pyrenees 2">Great Pyrenees 2</option>
                      <option value="Havanese 1">Havanese 1</option>
                      <option value="Havanese 2">Havanese 2</option>
                      <option value="Hovawart 1">Hovawart 1</option>
                      <option value="Hovawart 2">Hovawart 2</option>
                      <option value="Husky 1">Husky 1</option>
                      <option value="Husky 2">Husky 2</option>
                      <option value="Ibizan Hound 1">Ibizan Hound 1</option>
                      <option value="Ibizan Hound 2">Ibizan Hound 2</option>
                      <option value="Irish Setter 1">Irish Setter 1</option>
                      <option value="Irish Setter 2">Irish Setter 2</option>
                      <option value="Irish Soft Coated Wheaten Terrier 1">
                        Irish Soft Coated Wheaten Terrier 1
                      </option>
                      <option value="Irish Soft Coated Wheaten Terrier 2">
                        Irish Soft Coated Wheaten Terrier 2
                      </option>
                      <option value="Irish Terrier 1">Irish Terrier 1</option>
                      <option value="Irish Terrier 2">Irish Terrier 2</option>
                      <option value="Irish Wolfhound 1">
                        Irish Wolfhound 1
                      </option>
                      <option value="Irish Wolfhound 2">
                        Irish Wolfhound 2
                      </option>
                      <option value="Jack Russell 1">Jack Russell 1</option>
                      <option value="Jack Russell 2">Jack Russell 2</option>
                      <option value="Jack Russell Long-Haired 1">
                        Jack Russell Long-Haired 1
                      </option>
                      <option value="Jack Russell Long-Haired 2">
                        Jack Russell Long-Haired 2
                      </option>
                      <option value="Japanese Chin 1">Japanese Chin 1</option>
                      <option value="Japanese Chin 2">Japanese Chin 2</option>
                      <option value="Keeshond 1">Keeshond 1</option>
                      <option value="Keeshond 2">Keeshond 2</option>
                      <option value="Kooikerhondje 1">Kooikerhondje 1</option>
                      <option value="Kooikerhondje 2">Kooikerhondje 2</option>
                      <option value="Labradoodle 1">Labradoodle 1</option>
                      <option value="Labradoodle 2">Labradoodle 2</option>
                      <option value="Labrador Retriever 1">
                        Labrador Retriever 1
                      </option>
                      <option value="Labrador Retriever 2">
                        Labrador Retriever 2
                      </option>
                      <option value="Leonberger 1">Leonberger 1</option>
                      <option value="Leonberger 2">Leonberger 2</option>
                      <option value="Lhasa Apso 1">Lhasa Apso 1</option>
                      <option value="Lhasa Apso 2">Lhasa Apso 2</option>
                      <option value="Louisiana 1">Louisiana 1</option>
                      <option value="Louisiana 2">Louisiana 2</option>
                      <option value="Maltese 1">Maltese 1</option>
                      <option value="Maltese 2">Maltese 2</option>
                      <option value="Maltipoo 1">Maltipoo 1</option>
                      <option value="Maltipoo 2">Maltipoo 2</option>
                      <option value="Miniature Pinscher 1">
                        Miniature Pinscher 1
                      </option>
                      <option value="Miniature Pinscher 2">
                        Miniature Pinscher 2
                      </option>
                      <option value="Morkie 1">Morkie 1</option>
                      <option value="Morkie 2">Morkie 2</option>
                      <option value="Neapolitan Mastiff 1">
                        Neapolitan Mastiff 1
                      </option>
                      <option value="Neapolitan Mastiff 2">
                        Neapolitan Mastiff 2
                      </option>
                      <option value="Newfoundland 1">Newfoundland 1</option>
                      <option value="Newfoundland 2">Newfoundland 2</option>
                      <option value="Nova Scotia Duck Toller 1">
                        Nova Scotia Duck Toller 1
                      </option>
                      <option value="Nova Scotia Duck Toller 2">
                        Nova Scotia Duck Toller 2
                      </option>
                      <option value="Old English Sheepdog 1">
                        Old English Sheepdog 1
                      </option>
                      <option value="Old English Sheepdog 2">
                        Old English Sheepdog 2
                      </option>
                      <option value="Papillon 1">Papillon 1</option>
                      <option value="Papillon 2">Papillon 2</option>
                      <option value="Papitese 1">Papitese 1</option>
                      <option value="Papitese 2">Papitese 2</option>
                      <option value="Pekingese 1">Pekingese 1</option>
                      <option value="Pekingese 2">Pekingese 2</option>
                      <option value="Pit Bull 1">Pit Bull 1</option>
                      <option value="Pit Bull 2">Pit Bull 2</option>
                      <option value="Pit Bull Floppy Ears 1">
                        Pit Bull Floppy Ears 1
                      </option>
                      <option value="Pit Bull Floppy Ears 2">
                        Pit Bull Floppy Ears 2
                      </option>
                      <option value="Pomeranian 1">Pomeranian 1</option>
                      <option value="Pomeranian 2">Pomeranian 2</option>
                      <option value="Poodle 1">Poodle 1</option>
                      <option value="Poodle 2">Poodle 2</option>
                      <option value="Portuguese Water Dog 1">
                        Portuguese Water Dog 1
                      </option>
                      <option value="Portuguese Water Dog 2">
                        Portuguese Water Dog 2
                      </option>
                      <option value="Pug 1">Pug 1</option>
                      <option value="Pug 2">Pug 2</option>
                      <option value="Puggle 1">Puggle 1</option>
                      <option value="Puggle 2">Puggle 2</option>
                      <option value="Rat Terrier 1">Rat Terrier 1</option>
                      <option value="Rat Terrier 2">Rat Terrier 2</option>
                      <option value="Redbone Coonhound 1">
                        Redbone Coonhound 1
                      </option>
                      <option value="Redbone Coonhound 2">
                        Redbone Coonhound 2
                      </option>
                      <option value="Rhodesian Ridgeback 1">
                        Rhodesian Ridgeback 1
                      </option>
                      <option value="Rhodesian Ridgeback 2">
                        Rhodesian Ridgeback 2
                      </option>
                      <option value="Rottweiler 1">Rottweiler 1</option>
                      <option value="Rottweiler 2">Rottweiler 2</option>
                      <option value="Rough Collie 1">Rough Collie 1</option>
                      <option value="Rough Collie 2">Rough Collie 2</option>
                      <option value="Russian Toy 1">Russian Toy 1</option>
                      <option value="Russian Toy 2">Russian Toy 2</option>
                      <option value="Saint Bernard 1">Saint Bernard 1</option>
                      <option value="Saint Bernard 2">Saint Bernard 2</option>
                      <option value="Saluki 1">Saluki 1</option>
                      <option value="Saluki 2">Saluki 2</option>
                      <option value="Samoyed 1">Samoyed 1</option>
                      <option value="Samoyed 2">Samoyed 2</option>
                      <option value="Schnauzer 1">Schnauzer 1</option>
                      <option value="Schnauzer 2">Schnauzer 2</option>
                      <option value="Scottish Terrier 1">
                        Scottish Terrier 1
                      </option>
                      <option value="Scottish Terrier 2">
                        Scottish Terrier 2
                      </option>
                      <option value="Shar Pei 1">Shar Pei 1</option>
                      <option value="Shar Pei 2">Shar Pei 2</option>
                      <option value="Shetland Sheepdog 1">
                        Shetland Sheepdog 1
                      </option>
                      <option value="Shetland Sheepdog 2">
                        Shetland Sheepdog 2
                      </option>
                      <option value="Shiba Inu 1">Shiba Inu 1</option>
                      <option value="Shiba Inu 2">Shiba Inu 2</option>
                      <option value="Shih Tzu 1">Shih Tzu 1</option>
                      <option value="Shih Tzu 2">Shih Tzu 2</option>
                      <option value="Staffordshire Bull Terrier 1">
                        Staffordshire Bull Terrier 1
                      </option>
                      <option value="Staffordshire Bull Terrier 2">
                        Staffordshire Bull Terrier 2
                      </option>
                      <option value="Tibetan Mastiff 1">
                        Tibetan Mastiff 1
                      </option>
                      <option value="Tibetan Mastiff 2">
                        Tibetan Mastiff 2
                      </option>
                      <option value="Tibetan Spaniel 1">
                        Tibetan Spaniel 1
                      </option>
                      <option value="Tibetan Spaniel 2">
                        Tibetan Spaniel 2
                      </option>
                      <option value="Tibetan Terrier 1">
                        Tibetan Terrier 1
                      </option>
                      <option value="Tibetan Terrier 2">
                        Tibetan Terrier 2
                      </option>
                      <option value="Toy Fox Terrier 1">
                        Toy Fox Terrier 1
                      </option>
                      <option value="Toy Fox Terrier 2">
                        Toy Fox Terrier 2
                      </option>
                      <option value="Toy Poodle 1">Toy Poodle 1</option>
                      <option value="Toy Poodle 2">Toy Poodle 2</option>
                      <option value="Vizsla 1">Vizsla 1</option>
                      <option value="Vizsla 2">Vizsla 2</option>
                      <option value="Weimaraner 1">Weimaraner 1</option>
                      <option value="Weimaraner 2">Weimaraner 2</option>
                      <option value="Welsh Terrier 1">Welsh Terrier 1</option>
                      <option value="Welsh Terrier 2">Welsh Terrier 2</option>
                      <option value="West Highland Terrier 1">
                        West Highland Terrier 1
                      </option>
                      <option value="West Highland Terrier 2">
                        West Highland Terrier 2
                      </option>
                      <option value="Whippet 1">Whippet 1</option>
                      <option value="Whippet 2">Whippet 2</option>
                      <option value="Yorkshire Terrier 1">
                        Yorkshire Terrier 1
                      </option>
                      <option value="Yorkshire Terrier 2">
                        Yorkshire Terrier 2
                      </option>
                      <option value="Zuchon 1">Zuchon 1</option>
                      <option value="Zuchon 2">Zuchon 2</option>
                      <option value="Bengal ">Bengal </option>
                      <option value="British Shorthair/British Blue">
                        British Shorthair/British Blue
                      </option>
                      <option value="Burmese">Burmese</option>
                      <option value="Cornish Rex">Cornish Rex</option>
                      <option value="Maine Coon">Maine Coon</option>
                      <option value="Persian">Persian</option>
                      <option value="Scottish Fold">Scottish Fold</option>
                      <option value="Siamese">Siamese</option>
                      <option value="Sphinx">Sphinx</option>
                      <option value="Ferret">Ferret</option>
                      <option value="Guinea Pig">Guinea Pig</option>
                      <option value="Hamster">Hamster</option>
                      <option value="Horse">Horse</option>
                      <option value="Magpie">Magpie</option>
                      <option value="Parrot">Parrot</option>
                      <option value="African Grey Parrot">
                        African Grey Parrot
                      </option>
                      <option value="Rabbit 1">Rabbit 1</option>
                      <option value="Rabbit 2">Rabbit 2</option>
                      <option value="Rat">Rat</option>
                      <option value="Tortoise">Tortoise</option>
                      <option value="Turtle">Turtle</option>
                    </select>
                  </div>
                  <div className="messages" />
                </div>
              </div>
            </div>

            <div className="d-flex flex-row mb-4 ">
              <div className="pl-2 pr-2 bd-highlight select-size">
                <div
                  className="gpo-element gpo-form__group gpo-col-12 show-addon-for-input has-success"
                  data-element-id="select-14"
                  data-clo-display="hide"
                >
                  <div className="gpo-hidden-data">
                    <input
                      type="hidden"
                      data-field-name="First Pet Breed"
                      defaultValue
                    />
                  </div>
                  <label className="gpo-label " htmlFor="select-14">
                    <span className="label-content">
                      {" "}
                      {Numbers[index]} Pet Name
                    </span>
                    <span className="label-required">*</span>
                  </label>
                  <div className="gpo-select">
                    <input
                      type="text"
                      data-type="text"
                      className="gpo-input gpo-text"
                      id={`text-${index}`}
                      name="pet_name"
                      onChange={(e) => handleDataChange(e, index)}
                      data-maxlength={13}
                    />
                  </div>
                </div>
              </div>
            </div>
          </>
        ))
      : "";
  };

  const CustomOrderUI = () => {
    return avatar_preDrawn_details.length > 0
      ? avatar_preDrawn_details?.map((item, index) => (
          <>
            <div className="d-flex flex-row mb-4 ">
              <div className="pl-2 pr-2 bd-highlight select-size">
                <div
                  className="gpo-element gpo-form__group  gpo-col-12 show-addon-for-input "
                  data-element-id="file-1"
                  data-clo-display="hide"
                >
                  <div className="gpo-hidden-data">
                    <input
                      type="hidden"
                      className="gpo-hidden-file"
                      data-field-name="Photo of First Pet"
                      defaultValue
                    />
                  </div>
                  <label htmlFor="file-1" className="gpo-label ">
                    <span className="label-content">
                      Upload Photo Of First Pet
                    </span>
                  </label>
                  <div className="gpo-dropzone">
                    <span className="gpo-dropzone__btn">Choose File</span>
                    {/* <span className="gpo-dropzone__msg">
                      or drop file to upload
                    </span> */}
                    <input
                      type="file"
                      data-type="file"
                      id={`file-${index}`}
                      className="gpo-file gpo-dropzone__input"
                      name="image"
                      placeholder="or drop files to upload"
                      data-allowed_extensions="jpg,jpeg,png,heic"
                      accept=".jpg,.jpeg,.png,.heic"
                      onChange={(e) => handleCustomChange(e, index)}
                    />
                  </div>
                  <div className="messages" />
                </div>
              </div>
            </div>

            <div className="d-flex flex-row mb-4 ">
              <div className="pl-2 pr-2 bd-highlight select-size">
                <div
                  className="gpo-element gpo-form__group gpo-col-12 show-addon-for-input has-success"
                  data-element-id="select-14"
                  data-clo-display="hide"
                >
                  <div className="gpo-hidden-data">
                    <input
                      type="hidden"
                      data-field-name="First Pet Breed"
                      defaultValue
                    />
                  </div>
                  <label className="gpo-label " htmlFor="select-14">
                    <span className="label-content">
                      {" "}
                      {Numbers[index]} Pet Name
                    </span>
                    <span className="label-required">*</span>
                  </label>
                  <div className="gpo-select">
                    <input
                      type="text"
                      data-type="text"
                      className="gpo-input gpo-text"
                      id={`text-${index}`}
                      name="pet_name"
                      onChange={(e) => handleCustomChange(e, index)}
                      data-maxlength={13}
                    />
                  </div>
                </div>
              </div>
            </div>
          </>
        ))
      : "";
  };
  return (
    <>
      <section>
        <div className="container">
          <div className="row mt-40 ">
            {/* col-lg-6 PRODUCT IMAGE START */}
            <div className="col-lg-5 col-sm-12">
              <ImagesSlick images={data?.data?.product?.images} />
              {data?.data?.product?.is_wishlist ? (
                <i
                  className="fas fa-heart cart-orange-cstm"
                  style={{ color: "red" }}
                  onClick={() => mutate(data?.data?.product?._id)}
                />
              ) : (
                <i
                  className="far fa-heart cart-orange-cstm"
                  onClick={() => {
                    if (user?._id) mutate(data?.data?.product?._id);
                    else Swal.fire("Please Login First", "", "warning");
                  }}
                />
              )}
            </div>
            {/* col-lg-6 PRODUCT IMAGE END */}
            {/* col-lg-6 PRODUCT DETAILS start */}
            <div className="col-lg-4 col-sm-12">
              <h2 className="ff-thunder fc-purple">
                {data?.data?.product?.name}
              </h2>
              <div className="d-flex flex-row">
                <div className="p-2 bd-highlight">
                  <img
                    className="img-fluid img-testimonial"
                    src={`${image_url}${data?.data?.product?.vendor?.userImage}`}
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = "img/testimonial.jpg";
                    }}
                  />
                </div>
                <div className="p-2 bd-highlight mt-4">
                  <Link to={`/store/${data?.data?.product?.vendor?._id}`}>
                    <h5>{data?.data?.product?.vendor?.storeName}</h5>
                  </Link>
                </div>
              </div>
              <div className="d-flex"> 
                <h5 className="fc-purple">
                  Category:
                </h5>
                <div className=" bd-highlight mx-3">
                  <h5>{data?.data?.product?.category?.name}</h5>
                </div>
              </div>
              <div className="d-flex flex-row">
                <div className="pl-2 pr-2  bd-highlight">
                  <ul className="review-star">
                    <StarRatings
                      rating={data?.data?.product?.avgRatings}
                      starRatedColor="#EFAA25"
                      starHoverColor="#EFAA25"
                      numberOfStars={5}
                      name="rating"
                      starDimension="18px"
                      starSpacing="0px"
                    />
                  </ul>
                </div>
                <div className="pl-2 pr-2  bd-highlight">
                  <p className="fc-gray">
                    {ratings?.data?.avg_rating ? ratings?.data?.avg_rating : 0}{" "}
                    Ratings &amp; {ratings?.data?.total_ratings_count} Reviews
                  </p>
                </div>
              </div>
              <div className="d-flex flex-row mb-3">
                <div className="pl-2 pr-2 bd-highlight">
                  <h2 className="ff-thunder fc-orange">
                  ₵{data?.data?.product?.price}
                  </h2>
                </div>
              </div>
              <div className="d-flex flex-row mb-3">
                {data?.data?.product?.variations?.colors?.length > 0 && (
                  <div className="pl-2 pr-2 bd-highlight">
                    <p className="ff-thunder fc-purple mb-0">Color:</p>
                    <div className="radio-toolbar">
                      {data?.data?.product?.variations?.colors?.map((color) => (
                        <>
                          <input
                            type="radio"
                            id={`radioApple${color?._id}`}
                            name="radioFruit"
                            onChange={() => {
                              setVariation({
                                ...selected_variation,
                                color,
                              });
                            }}
                            checked={
                              selected_variation?.color?._id === color?._id
                            }
                          />
                          <label
                            htmlFor={`radioApple${color?._id}`}
                            style={{
                              backgroundColor: color?.hex,
                            }}
                          />
                        </>
                      ))}
                    </div>
                  </div>
                )}
                <div className="pl-2 pr-2 bd-highlight mt-1">
                  <p className="ff-thunder fc-purple mb-0">Quantity:</p>
                  <div className="quantity buttons_added">
                    <input
                      type="button"
                      defaultValue="-"
                      className="minus btn-minus-cstm"
                      onClick={() => {
                        if (quantity > 1) setQuantity(quantity - 1);
                      }}
                    />
                    <input
                      type="number"
                      step={1}
                      min={1}
                      max
                      name="quantity"
                      title="Qty"
                      className="input-text qty text"
                      size={4}
                      pattern
                      inputMode
                      value={quantity}
                      onChange={(e) => {
                        if (
                          e.target.value >= 1 &&
                          e.target.value <= data?.data?.product?.stock
                        )
                          setQuantity(parseInt(e.target.value));
                      }}
                    />
                    <input
                      type="button"
                      defaultValue="+"
                      className="plus btn-plus-cstm"
                      onClick={() => {
                        if (parseInt(quantity) < data?.data?.product?.stock)
                          setQuantity(parseInt(quantity) + 1);
                      }}
                    />
                  </div>
                </div>
              </div>
              {data?.data?.product?.variations?.sizes?.length > 0 && (
                <div className="d-flex flex-row mb-3">
                  <div className="pl-2 pr-2 bd-highlight select-size">
                    <p className="ff-thunder fc-purple mb-0">Select Size:</p>
                    <ul>
                      {data?.data?.product?.variations?.sizes?.map((size) => (
                        <li
                          onClick={() => {
                            setVariation({
                              ...selected_variation,
                              size,
                            });
                          }}
                          style={
                            selected_variation?.size?._id === size?._id
                              ? selected
                              : { cursor: "pointer" }
                          }
                        >
                          <Link
                            to="#"
                            style={
                              selected_variation?.size === size?._id
                                ? selected
                                : {}
                            }
                          >
                            {getFirstWordOfEveryLetter(size?.name)}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              )}
              <br/>
              {/* <div className="d-flex flex-row mb-4 ">
                <div className="pl-2 pr-2 bd-highlight select-size">
                  <div className="gpo-select">
                    <select
                      data-type="select"
                      className="gpo-select"
                      id="select-5"
                      name="iPhone Model"
                      data-name="properties[iPhone Model]"
                      data-field-name="iPhone Model"
                      onChange={(e) => setModel(e.target.value)}
                    >
                      <option selected disabled="disabled" value>
                        Choose Model
                      </option>
                      <option value="iPhone X">iPhone X</option>
                      <option value="iPhone XR">iPhone XR</option>
                      <option value="iPhone XS">iPhone XS</option>
                      <option value="iPhone XS Max">iPhone XS Max</option>
                      <option value="iPhone 11">iPhone 11</option>
                      <option value="iPhone 11 Pro">iPhone 11 Pro</option>
                      <option value="iPhone 11 Pro Max">
                        iPhone 11 Pro Max
                      </option>
                      <option value="iPhone 12 mini">iPhone 12 mini</option>
                      <option value="iPhone 12">iPhone 12</option>
                      <option value="iPhone 12 Pro">iPhone 12 Pro</option>
                      <option value="iPhone 12 Pro Max">
                        iPhone 12 Pro Max
                      </option>
                      <option value="iPhone 13 mini">iPhone 13 mini</option>
                      <option value="iPhone 13">iPhone 13</option>
                      <option value="iPhone 13 Pro">iPhone 13 Pro</option>
                      <option value="iPhone 13 Pro Max">
                        iPhone 13 Pro Max
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="d-flex flex-row mb-4 ">
                <div className="pl-2 pr-2 bd-highlight select-size">
                  <div className="gpo-select is-filled">
                    <select
                      data-type="select"
                      className="gpo-select"
                      id="select-12"
                      name="Avatar Type"
                      data-name="properties[Avatar Type]"
                      data-field-name="Avatar Type"
                      presence
                      onChange={(e) => setAvatarType(e.target.value)}
                    >
                      <option selected disabled="disabled" value="">
                        Choose Avatar Type
                      </option>
                      <option value="Pre-Drawn Avatar">Pre-Drawn Avatar</option>
                      <option value="Upload Your Own Photo">
                        Upload Your Own Photo
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              {avatar_type == "Pre-Drawn Avatar" && (
                <>
                  <div className="d-flex flex-row mb-4 ">
                    <div className="pl-2 pr-2 bd-highlight select-size">
                      <div
                        className="gpo-element gpo-form__group gpo-col-12 show-addon-for-input has-success"
                        data-element-id="select-1"
                        data-clo-display="hide"
                      >
                        <div className="gpo-hidden-data">
                          <input
                            type="hidden"
                            data-field-name="Number of Pets"
                            defaultValue="1 Pet"
                            name="properties[Number of Pets]"
                          />
                        </div>
                        <label className="gpo-label " htmlFor="select-1">
                          <span className="label-content">Number Of Pets</span>
                          <span className="label-required">*</span>
                        </label>
                        <div className="gpo-select">
                          <select
                            data-type="select"
                            className="gpo-select"
                            id="select-1"
                            name="Number of Pets"
                            data-name="properties[Number of Pets]"
                            data-field-name="Number of Pets"
                            onChange={(e) => setNoOfPets(e.target.value)}
                          >
                            <option disabled="disabled" value />
                            <option value="1" selected>
                              1 Pet
                            </option>
                            <option
                              value="2"
                              gpo-data-product-handle="extra-pre-drawn-avatar-2-pets"
                              gpo-data-variant-id={41094469779619}
                            >
                              2 Pets (+ $1.00)
                            </option>
                            <option
                              value="3"
                              gpo-data-product-handle="extra-pre-drawn-avatar-2-pets"
                              gpo-data-variant-id={41094469812387}
                            >
                              3 Pets (+ $2.00)
                            </option>
                            <option
                              value="4"
                              gpo-data-product-handle="extra-pre-drawn-avatar-2-pets"
                              gpo-data-variant-id={41094469845155}
                            >
                              4 Pets (+ $3.00)
                            </option>
                          </select>
                        </div>
                        <div className="messages" />
                      </div>
                    </div>
                  </div>

                  {PreDrawnOrderUI()}
                </>
              )}
              {avatar_type == "Upload Your Own Photo" && (
                <>
                  <div className="d-flex flex-row mb-4 ">
                    <div className="pl-2 pr-2 bd-highlight select-size">
                      <div
                        className="gpo-element gpo-form__group gpo-col-12 show-addon-for-input has-success"
                        data-element-id="select-1"
                        data-clo-display="hide"
                      >
                        <div className="gpo-hidden-data">
                          <input
                            type="hidden"
                            data-field-name="Number of Pets"
                            defaultValue="1 Pet"
                            name="properties[Number of Pets]"
                          />
                        </div>
                        <label className="gpo-label " htmlFor="select-1">
                          <span className="label-content">Number Of Pets</span>
                          <span className="label-required">*</span>
                        </label>
                        <div className="gpo-select">
                          <select
                            data-type="select"
                            className="gpo-select"
                            id="select-1"
                            name="Number of Pets"
                            data-name="properties[Number of Pets]"
                            data-field-name="Number of Pets"
                            onChange={(e) => setNoOfPets(e.target.value)}
                          >
                            <option disabled="disabled" value />
                            <option value="1" selected>
                              1 Pet (+ $2.00)
                            </option>
                            <option
                              value="2"
                              gpo-data-product-handle="extra-pre-drawn-avatar-2-pets"
                              gpo-data-variant-id={41094469779619}
                            >
                              2 Pets (+ $4.00)
                            </option>
                            <option
                              value="3"
                              gpo-data-product-handle="extra-pre-drawn-avatar-2-pets"
                              gpo-data-variant-id={41094469812387}
                            >
                              3 Pets (+ $6.00)
                            </option>
                            <option
                              value="4"
                              gpo-data-product-handle="extra-pre-drawn-avatar-2-pets"
                              gpo-data-variant-id={41094469845155}
                            >
                              4 Pets (+ $8.00)
                            </option>
                          </select>
                        </div>
                        <div className="messages" />
                      </div>
                    </div>
                  </div>

                  {CustomOrderUI()}
                </>
              )} */}

              <div className="d-flex flex-row mb-3">
                <div className="pl-2 pr-2 bd-highlight select-size">
                  <Link
                    to="#"
                    className="btn-orange"
                    onClick={() => {
                      if (
                        data?.data?.product?.variations &&
                        data?.data?.product?.variations?.sizes?.length > 0 &&
                        selected_variation?.size?._id === undefined
                      ) {
                        alert("Please Select a Size");
                      } else if (
                        data?.data?.product?.variations &&
                        data?.data?.product?.variations?.colors?.length > 0 &&
                        selected_variation?.color?._id === undefined
                      ) {
                        alert("Please Select a Color");
                      } else {
                        addItemToCart({
                          _id: data?.data?.product?._id,
                          quantity,
                          variations: selected_variation,
                          model,
                          avatar_type,
                          avatar_details:
                            avatar_type == "Pre-Drawn Avatar"
                              ? avatar_preDrawn_details
                              : avatar_type == "Upload Your Own Photo"
                              ? avatar_Custom_details
                              : [],
                        });
                      }
                    }}
                  >
                    <i className="fas fa-shopping-cart" /> Add To Cart
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container">
          <div className="row mt-30">
            <div className="col-lg-12 mt-5">
              <h3 className="ff-thunder fc-purple">Product Description</h3>
              <p className="mt-2" style={{ whiteSpace: "pre-line" }}>
                {data?.data?.product?.specifications}
              </p>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container">
          <div className="row">
            {/* Col-lg-12 REVIEW TABS START */}
            <div className="col-lg-12 review-tabs">
              <ul className="nav nav-pills" role="tablist">
                <li className="nav-item">
                  <a
                    className="nav-link active ff-thunder fc-purple"
                    data-toggle="pill"
                    href="#reviews"
                  >
                    <h3>Reviews</h3>
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" data-toggle="pill" href="#menu1">
                    <h3>More From Seller</h3>
                  </a>
                </li>
              </ul>
              {/* Tab panes */}
              <div className="tab-content">
                <div id="reviews" className="container tab-pane active">
                  <br />
                  <div className="row">
                    <div className="col-lg-6 col-sm-12">
                      <p className="ff-thunder fc-orange">
                        {ratings?.data?.avg_rating
                          ? ratings?.data?.avg_rating
                          : 0}{" "}
                        Stars
                      </p>
                      <ul className="review-star">
                        <StarRatings
                          rating={
                            ratings?.data?.avg_rating
                              ? ratings?.data?.avg_rating
                              : 0
                          }
                          starRatedColor="#EFAA25"
                          starHoverColor="#EFAA25"
                          numberOfStars={5}
                          name="rating"
                          starDimension="18px"
                          starSpacing="0px"
                        />
                      </ul>
                      <div className="clearfix" />
                      <p className="fc-lgray">
                        {ratings?.data?.avg_rating
                          ? ratings?.data?.avg_rating
                          : 0}{" "}
                        Ratings &amp; {ratings?.data?.total_ratings_count}{" "}
                        Reviews
                      </p>
                      {!user?._id ? (
                        <button className="btn-orange" type="button">
                          PLEASE LOGIN TO WRITE YOUR REVIEW
                        </button>
                      ) : (
                        <button
                          className="btn-orange"
                          type="button"
                          data-toggle="modal"
                          data-target="#exampleModalCenter1"
                        >
                          WRITE YOUR REVIEW
                        </button>
                      )}
                    </div>
                    <div className="col-lg-6 col-sm-12">
                      <div className="row mt-10">
                        <div className="col-lg-1 col-sm-12 pr-0 pl-0">
                          <small>1 STAR</small>
                        </div>
                        <div className="col-lg-10 col-sm-12 p-0 mt-1">
                          <div className="progress">
                            <div
                              className="progress-bar bg-warning"
                              style={{ width: `${ratings?.data?.one_star}%` }}
                            />
                          </div>
                        </div>
                        <div className="col-lg-1 col-sm-12 pr-0">
                          <small>{ratings?.data?.one_star}</small>
                        </div>
                      </div>
                      <div className="row mt-10">
                        <div className="col-lg-1 col-sm-12 pr-0 pl-0">
                          <small>2 STAR</small>
                        </div>
                        <div className="col-lg-10 col-sm-12 p-0 mt-1">
                          <div className="progress">
                            <div
                              className="progress-bar bg-warning"
                              style={{ width: `${ratings?.data?.two_star}%` }}
                            />
                          </div>
                        </div>
                        <div className="col-lg-1 col-sm-12 pr-0">
                          <small>{ratings?.data?.two_star}</small>
                        </div>
                      </div>
                      <div className="row mt-10">
                        <div className="col-lg-1 col-sm-12 pr-0 pl-0">
                          <small>3 STAR</small>
                        </div>
                        <div className="col-lg-10 col-sm-12 p-0 mt-1">
                          <div className="progress">
                            <div
                              className="progress-bar bg-warning"
                              style={{ width: `${ratings?.data?.three_star}%` }}
                            />
                          </div>
                        </div>
                        <div className="col-lg-1 col-sm-12 pr-0">
                          <small>{ratings?.data?.three_star}</small>
                        </div>
                      </div>
                      <div className="row mt-10">
                        <div className="col-lg-1 col-sm-12 pr-0 pl-0">
                          <small>4 STAR</small>
                        </div>
                        <div className="col-lg-10 col-sm-12 p-0 mt-1">
                          <div className="progress">
                            <div
                              className="progress-bar bg-warning"
                              style={{ width: `${ratings?.data?.four_star}%` }}
                            />
                          </div>
                        </div>
                        <div className="col-lg-1 col-sm-12 pr-0">
                          <small>{ratings?.data?.four_star}</small>
                        </div>
                      </div>
                      <div className="row mt-10">
                        <div className="col-lg-1 col-sm-12 pr-0 pl-0">
                          <small>5 STAR</small>
                        </div>
                        <div className="col-lg-10 col-sm-12 p-0 mt-1">
                          <div className="progress">
                            <div
                              className="progress-bar bg-warning"
                              style={{ width: `${ratings?.data?.five_star}%` }}
                            />
                          </div>
                        </div>
                        <div className="col-lg-1 col-sm-12 pr-0">
                          <small>{ratings?.data?.five_star}</small>
                        </div>
                      </div>
                    </div>
                    {ratings_loading ? (
                      <div className="row mt-3">
                        <div className="col-lg-4 mt-4">
                          <ProductCardSkeleton />
                        </div>
                        <div className="col-lg-4 mt-4">
                          <ProductCardSkeleton />
                        </div>
                        <div className="col-lg-4 mt-4">
                          <ProductCardSkeleton />
                        </div>
                      </div>
                    ) : (
                      <Slider
                        {...settings}
                        slidesToShow={
                          ratings?.data?.ratings?.length > 1 ? 3 : 1
                        }
                      >
                        {ratings?.data?.ratings?.map((rating) => (
                          <div
                            className={`col-lg-${
                              ratings?.data?.ratings?.length >= 3 ? 12 : 4
                            }`}
                          >
                            <ReviewCard rating={rating} />
                          </div>
                        ))}
                      </Slider>
                    )}
                  </div>
                </div>
                <div id="menu1" className="container tab-pane fade">
                  <br />
                  <div className="row">
                    {/* ============= product =============  */}
                    {data?.data?.more_seller_products?.map((product) => (
                      <ProductCard
                        product={product}
                        key={product?._id}
                        lg={3}
                      />
                    ))}
                    {/* ============= product =============  */}
                  </div>
                </div>
              </div>
            </div>
            {/* Col-lg-12 REVIEW TABS END */}
          </div>
        </div>
      </section>
      <section>
        <div className="container">
          <div className="row mb-40 p-55-0">
            {/* Col-lg-12 REVIEW TABS START */}
            <div className="col-lg-12 bt-1 text-center">
              <h1 className="ff-thunder fc-purple mt-40">
                Recommended Products
              </h1>
              <p className="ff-thunder fc-orange">
                We Support Producers By Selling Handcrafted, Sustainable
                Products.
              </p>
            </div>
            {/* ============= product =============  */}
            {data?.data?.recommended_products?.map((product) => (
              <ProductCard product={product} key={product?._id} lg={3} />
            ))}
            {/* ============= product =============  */}
          </div>
        </div>
      </section>
      <div
        className="modal fade login-modal"
        id="exampleModalCenter1"
        tabIndex={-1}
        aria-labelledby="exampleModalCenterTitle"
        style={{ display: "none" }}
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content modal-cstm">
            <div className="modal-header modal-headerClose">
              {/*         <h5 class="modal-title" id="exampleModalLongTitle">Modal title</h5> */}
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body p-20-40">
              <div className="text-center">
                <h3 className="ff-thunder fc-purple"> ADD REVIEWS</h3>
              </div>
              <div className="d-flex flex-row mt-20 justify-content-center">
                <div className="p-2 bd-highlight"></div>
                <div className="p-2 bd-highlight">
                  <h5 className="fc-orange mb-0">{user?.name}</h5>
                  <p className="mb-0">
                    {moment(
                      review_created_at ? review_created_at : new Date()
                    ).format("LL | hh:mm a")}
                  </p>
                  <ul className="review-star">
                    <StarRatings
                      rating={rating}
                      starRatedColor="#EFAA25"
                      starHoverColor="#EFAA25"
                      numberOfStars={5}
                      name="rating"
                      starDimension="18px"
                      starSpacing="0px"
                      changeRating={(newRating, name) => {
                        setRating(newRating);
                      }}
                    />
                  </ul>
                </div>
              </div>
              <div className="text-left">
                <div className="row">
                  <div className="col mt-30">
                    <textarea
                      rows={5}
                      className="form-control textarea-cstm"
                      value={comment}
                      onChange={(e) => setComment(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              <div className="text-center">
                <div className="row mb-20">
                  <div className="col mt-40 text-center mt-20">
                    <button
                      type="button"
                      className="btn-orange"
                      data-dismiss="modal"
                      aria-label="Close"
                      data-toggle="modal"
                      onClick={() =>
                        add_rating_mutate({
                          rating,
                          comment,
                          product: data?.data?.product?._id,
                          id: review_id,
                        })
                      }
                    >
                      SUBMIT
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
