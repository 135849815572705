import React from "react";
import { useEffect } from "react";
import { useQuery } from "react-query";
import { getRandomProducts } from "../../Apis/Product";
import ProductCard from "../../Components/ProductCard";
import { query_stale_time } from "../../Helpers/Helpers";
import ProductCardSkeleton from "../../Components/SkeletonCards/ProductCard";
import AOS from 'aos';
import 'aos/dist/aos.css'


export default function FeaturedProducts() {
  const { isLoading, data } = useQuery(
    ["featured_products"],
    () => getRandomProducts(),
    {
      staleTime: query_stale_time,
    }
  );

  useEffect(() => {
    AOS.init({disable: 'mobile',mirror:true});
    AOS.refresh();
  }, []);

  return (<>
    <section className="bg-about">
      <img
            style={{
              width: "400px",
              position: "absolute",
              left: 0,
              bottom: 0,
              zIndex: 1,
              opacity: 0.8,
            }}
            src="images/bannerLeft.png"
            alt=""
            className="img-fluid"
          />

          <img
            style={{
              width: "400px",
              position: "absolute",
              right: 0,
              top: 0,
              zIndex: 1,
              opacity: 0.8,
            }}
            src="images/bannerRight22.png"
            alt=""
            className="img-fluid"
          />
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-center">
            <div style={{zIndex: 2}} data-aos="fade-up"
                data-aos-delay="0" data-aos-easing="ease-in-sine">
              <h1 className="bannerHeading2">Featured Products</h1>
            </div>
            </div>
          </div>
        </div>
      </section>
      <section className="p-55-0">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 text-center">
          <div style={{zIndex: 2}} data-aos="fade-up"
                data-aos-delay="200" data-aos-easing="ease-in-sine">
            <h1 className="ff-thunder fc-purple">FEATURED PRODUCTS</h1>
            <p
              className="ff-thunder fc-orange"
              daata-aoos="fade-up"
              daata-aoos-duration="1000"
            >
              We support small producers by selling handcrafted, sustainable
              products.
            </p>
            </div>
          </div>
          <div className="row  mt-40" style={{width:"100%"}}>
            {/* ============= product =============  */}
            {isLoading ? (
              <>
                <div className="col-lg-3 col-sm-12 col-xs-12 mt-10 mb-10">
                  <ProductCardSkeleton />
                </div>
                <div className="col-lg-3 col-sm-12 col-xs-12 mt-10 mb-10">
                  <ProductCardSkeleton />
                </div>
                <div className="col-lg-3 col-sm-12 col-xs-12 mt-10 mb-10">
                  <ProductCardSkeleton />
                </div>
                <div className="col-lg-3 col-sm-12 col-xs-12 mt-10 mb-10">
                  <ProductCardSkeleton />
                </div>
                
              </>
            ) : data?.data?.products?.docs?.length === 0 ? (
              <div className="col-12 mt-10 mb-10">
                <h1>No Items Found</h1>
              </div>
            ) : (
              data?.data?.products?.map((product) => (
                
                <ProductCard key={product?._id} product={product} lg={3} />
              ))
            )}
            {/* ============= product =============  */}
          </div>
        </div>
      </div>
    </section>
  </>

  );
}
